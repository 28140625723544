import loadjs from 'loadjs'
class VelocityApplet {
  constructor(props) {
    this.documentContent = props.documentContent
    this.appletView = props.appletView
    this.context = 'context' in props ? props.context : null
    this.url = props.url
    this.documentProperties = props.properties
    this.libArray = []
    this.eventListeners = {}
  }

  async init() {
    return new Promise((resolve) => {
      // eslint-disable-next-line prettier/prettier
      (async () => {
        this.velocityInstance = await this.createVelocityInstance()
        var self = this
        var postObj
        switch (true) {
          case typeof this.documentContent === 'string':
            postObj = {
              entity: 'BuilderDocuments',
              filter: [
                {
                  name: 'IsEqualTo',
                  arguments: [
                    {
                      name: 'GetFieldValue',
                      arguments: [
                        {
                          value: 'ObjectName',
                        },
                      ],
                    },
                    {
                      value: this.documentContent,
                    },
                  ],
                },
              ],
            }

            window.networkManager
              .post('/api/v2/query', postObj, {
                headers: {
                  'Content-Type': 'application/json',
                },
              })
              .then(function (response) {
                if (response && response.status === 200) {
                  self.documentContent = window.Open.stringToObject(
                    response.data.data.BuilderDocuments[0].fields.Content
                  )
                  self.mergeStructureWithProperties(
                    self.documentProperties,
                    self.documentContent
                  )
                  self.odpProject =
                    self.velocityInstance.getProjectFromVelocity(
                      self.documentContent
                    )
                  self.velocityInstance.init(self.odpProject).then(() => {
                    resolve(self.velocityInstance)
                  })
                }
              })
            break
          case typeof this.documentContent == 'object' &&
            typeof this.documentContent.structure !== 'undefined':
            this.mergeStructureWithProperties(
              this.documentProperties,
              this.documentContent
            )
            this.odpProject = this.velocityInstance.getProjectFromVelocity(
              this.documentContent
            )
            self.velocityInstance.init(self.odpProject).then(() => {
              resolve(self.velocityInstance)
            })
            break
          case typeof this.appletView !== 'undefined':
            var objectName = this.appletView
            postObj = {
              entity: 'AppletViews',
              filter: [
                {
                  name: 'IsEqualTo',
                  arguments: [
                    {
                      name: 'GetFieldValue',
                      arguments: [
                        {
                          value: 'ObjectName',
                        },
                      ],
                    },
                    {
                      value: objectName,
                    },
                  ],
                },
              ],
              include: [
                {
                  relationship:
                    'AppletViews_AppletBuilderDocument_BuilderDocuments',
                },
              ],
              fields: {
                AppletViews: [
                  {
                    field: 'AppletBuilderDocument',
                  },
                ],
                BuilderDocuments: [
                  {
                    field: 'Content',
                  },
                ],
              },
            }

            window.networkManager
              .post('/api/v2/query', postObj)
              .then(function (response) {
                if (response && response.status === 200) {
                  self.documentContent = window.Open.stringToObject(
                    response.data.data.BuilderDocuments[0].fields.Content
                  )
                  self.mergeStructureWithProperties(
                    self.documentProperties,
                    self.documentContent
                  )
                  self.odpProject =
                    self.velocityInstance.getProjectFromVelocity(
                      self.documentContent
                    )
                  self.velocityInstance.init(self.odpProject).then(() => {
                    resolve(self.velocityInstance)
                  })
                }
              })
            break
          case typeof this.url !== 'undefined' && this.url !== '':
            window.networkManager.get(this.url).then(function (resp) {
              self.documentContent = resp.data
              self.mergeStructureWithProperties(
                self.documentProperties,
                self.documentContent
              )
              self.odpProject = self.velocityInstance.getProjectFromVelocity(
                self.documentContent
              )
              self.velocityInstance.init(self.odpProject).then(() => {
                resolve(self.velocityInstance)
              })
            })
            break
          default:
            break
        }
      })()
    })
  }

  render(domElement) {
    console.log('documentContent', this.documentContent)
    var self = this
    //   init i render
    var containerRef =
      typeof domElement == 'string'
        ? document.querySelector(domElement)
        : domElement
    // self.velocityInstance.init(self.odpProject).then(() => {
    self.velocityInstance.render(containerRef)
    // })
  }

  createVelocityInstance() {
    return new Promise((resolve) => {
      window.loadjs = loadjs

      var self = this

      if (!loadjs.isDefined('runnerReady')) {
        this.includeLibraries()
        if (this.libArray.length > 0) {
          loadjs(this.libArray, 'runnerReady')
          loadjs.ready('runnerReady', {
            success() {
              const velocityInstance = new window.RM.ODP_Velocity({
                context: self.context,
              })

              self.velocityInstance = velocityInstance
              self.addDelayedListeners()

              // self.render()

              resolve(self.velocityInstance)
            },
            error() {
              console.error('Can`t load grid dependacies!')
            },
          })
        } else {
          const velocityInstance = new window.RM.ODP_Velocity({
            context: self.context,
          })
          self.velocityInstance = velocityInstance
          self.addDelayedListeners()
          resolve(self.velocityInstance)
        }
      } else {
        loadjs.ready('runnerReady', {
          success() {
            if (!self.velocityInstance) {
              const velocityInstance = new window.RM.ODP_Velocity({
                context: self.context,
              })

              self.velocityInstance = velocityInstance
              self.addDelayedListeners()
              // self.render()
            } else {
              // self.removeSnippets()
              // self.render()
            }

            resolve(self.velocityInstance)
          },
          error() {
            console.error('Can`t load grid dependacies!')
          },
        })
      }
    })
  }

  includeLibraries() {
    // let oidc, oauth
    let ODPUtils, odp
    // oidc = '/lib/oidc-client/oidc-client.1.10.1.min.js'
    // oauth = '/lib/rm-oauth/rm.oauth.0.2.0.js'
    // open = '/open/open.js'
    ODPUtils = '/lib/VelocityUI/RM.ODP.Data.Utils.js'
    odp = '/lib/VelocityUI/odp_all.min.js'

    // this.libArray.push(oidc)
    // this.libArray.push(oauth)
    // this.libArray.push(open)

    if (
      typeof window.RM === 'undefined' ||
      typeof window.RM.ODP_Velocity === 'undefined'
    ) {
      this.libArray.push(ODPUtils)
      this.libArray.push(odp)
    }

    if (!window.networkManager) {
      this.libArray.push('/lib/axios/0.21.0/axios.min.js')
      this.libArray.push('/lib/axios-middleware/0.3.1/axios-middleware.min.js')
      this.libArray.push(
        '/lib/@researchmetrics/network-manager/3.0.0/network-manager.umd.js'
      )
    }
  }

  mergeStructureWithProperties(viewDocumentProperties, viewDcoumentContent) {
    if (typeof viewDocumentProperties !== 'undefined') {
      /*viewDocumentProperties - comes in as object with uuid as key*/
      var structure = viewDcoumentContent.structure /*this comes in as array*/
      for (let prop in viewDocumentProperties) {
        this.updateSnippetStructure(
          structure,
          prop,
          viewDocumentProperties[prop]
        )
      }
    }
  }

  updateSnippetStructure(structure, propID, documentProperties) {
    var self = this
    /*Because we are trying to merge object and array.
     * If this function is missing object just never finds its key and merge is unsuccessful*/
    structure.forEach(function (snippet) {
      if (snippet.id === propID) {
        /*We are merging only parameters*/
        self._mergeDeep(snippet.parameters, documentProperties, 20)
      } else {
        if (snippet.childNodes && snippet.childNodes.length > 0) {
          self.updateSnippetStructure(
            snippet.childNodes,
            propID,
            documentProperties
          )
        }
      }
    })
  }

  _mergeDeep(target, context, maxDepth) {
    const isObject = (item) => {
      return item && typeof item === 'object'
    }
    for (const key in context) {
      if (isObject(context[key])) {
        if (typeof target[key] === 'undefined') {
          target[key] = context[key]
          continue
        }
        if (maxDepth === 0) {
          target[key] = context[key]
        } else {
          this._mergeDeep(target[key], context[key], --maxDepth)
        }
      } else {
        target[key] = context[key]
      }
    }
  }

  addDelayedListeners() {
    var listeners = this.eventListeners
    for (let listener in listeners) {
      var currentListener = listeners[listener]
      var eventName = currentListener.eventName
      var functionToExecute = currentListener.functionToExecute
      if (eventName == 'onStructureReady') {
        var self = this
        ;(function (eventName, functionToExecute) {
          self.velocityInstance.addEventListener(eventName, (event) => {
            console.log(event)
            var topLevel = self.velocityInstance.topSnippet
            if (
              topLevel &&
              self.velocityInstance._project[topLevel].length > 0
            ) {
              self.velocityInstance._project[topLevel][0].addEventListener(
                eventName,
                functionToExecute
              )
            }
          })
        })(eventName, functionToExecute)
      } else {
        this.addEventListener(eventName, functionToExecute)
      }
    }
  }

  addEventListener(eventName, functionToExecute) {
    if (this.velocityInstance) {
      var topLevel = this.velocityInstance.topSnippet
      if (topLevel && this.velocityInstance._project[topLevel].length > 0) {
        if (eventName == 'onStructureReady') {
          var self = this
          this.velocityInstance.addEventListener(eventName, () => {
            self.velocityInstance._project[topLevel][0].addEventListener(
              eventName,
              functionToExecute
            )
          })
        } else {
          this.velocityInstance._project[topLevel][0].addEventListener(
            eventName,
            functionToExecute
          )
        }
      }
    } else {
      this.eventListeners[eventName] = {
        eventName: eventName,
        functionToExecute: functionToExecute,
      }
    }
  }

  removeSnippets() {
    var instanceIDs = []

    for (var i = 0; i < this.velocityInstance._project.top.length; i++) {
      instanceIDs.push(this.velocityInstance._project.top[i]._instanceID)
    }
    this.velocityInstance.removeSnippet(instanceIDs, true)
    this.container.innerHTML = ''
  }

  destroy() {
    this.removeSnippets()
    if (this.isContainerExpanded) {
      this.container.style.height = ''
    }
    this.velocityInstance = null
    this.container = null
    this.libArray = null
    this.documentContent = null
  }
}

if (typeof window == 'object') {
  window.VelocityApplet = VelocityApplet
}

export default VelocityApplet
